import React, { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import FormLogo from 'components/formLogo/FormLogo';
import useProjectId from 'hooks/useProjectId';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useLoginUser from '../../../queries/user/useLoginUser';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import TextComponent from '/components/form/Text';
import FormContainer from '/components/formContainer/FormContainer';

interface FormValues {
  email: string;
  password: string;
}

/**
 * Renders Login Page
 */
const Login: React.FC = () => {
  const projectId = useProjectId();
  const loginUser = useLoginUser();
  const { handleSubmit, control, setError } = useForm<FormValues>({ mode: 'all' });

  /**
   * check if any error has occured
   * set error for respective fields.
   * if there is a server error, just open a snackbar
   */
  useEffect(() => {
    if (loginUser.isError) {
      const errorMessage = (loginUser.error as any).response?.data?.error?.message;

      switch (errorMessage) {
        case 'There is no account with this email':
          setError('email', {
            type: 'authentication',
            message: errorMessage,
          });
          break;
        case 'The provided email is not confirmed':
          setError('email', {
            type: 'authentication',
            message: errorMessage,
          });
          break;
        case 'Invalid password':
          setError('password', {
            type: 'authentication',
            message: errorMessage,
          });
          break;
        default:
          break;
      }
    }
  }, [loginUser.isError]);

  /**
   * Tries to log in the user with data provided
   * @param data data that gets passed from React Hook Form
   */
  const onSubmit = (data: FormValues) => {
    loginUser.mutate(data);
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormLogo />

            <TextComponent
              name="email"
              control={control}
              label="E-mail"
              rules={{
                required: { value: true, message: 'Enter e-mail' },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid E-mail address',
                },
              }}
            />

            <TextComponent
              name="password"
              control={control}
              label="Password"
              type="password"
              rules={{
                required: { value: true, message: 'Enter password' },
              }}
            />

            <LoadingButton
              style={{ marginBottom: '16px', marginTop: '16px', width: '100%' }}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="secondary"
              type="submit"
              loading={loginUser.isPending}
            >
              Login
            </LoadingButton>

            <Link to={`/${projectId}/forgot`} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="secondary" style={{ marginBottom: 8 }}>
                Forgot Password?
              </Typography>
            </Link>
            <Link to={`/${projectId}/CreateAccount`} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="secondary">
                Don’t have an account? Register here
              </Typography>
            </Link>
          </form>
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default Login;
