import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import { getInProject } from 'queries/user/user-in-project';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteToken, setToken } from 'utils/token';

interface MutationProps {
  email: string;
  password: string;
}

const useLoginUser = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const feedback = useOpenFeedback();

  const loginUser = useMutation({
    mutationFn: async ({ email, password }: MutationProps) => {
      const resp = await Backend.request<{ token: string }>(
        'post',
        `/account/login/${projectId}`,
        {},
        {
          email: email.trim().toLowerCase(),
          password,
        }
      );
      setToken(resp.data.data.token);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['userInProject', projectId, true] });
      if ((await getInProject({ projectId, loggedIn: true })).isSigned) {
        navigate(`/${projectId}/events`);
      } else {
        navigate(`/${projectId}/projectInfo`);
      }
    },
    onError: (error: { response: { data: { error: { message: string } } } }) => {
      feedback.openError(`Login failed: ${error.response.data.error.message ?? 'Unknown error'}`);
    },
  });

  const asyncLogout = async () => {
    await queryClient.cancelQueries({ queryKey: ['userInProject', projectId, true] });
    deleteToken();
  };

  useEffect(() => {
    asyncLogout();
  }, []);

  return loginUser;
};

export default useLoginUser;
